<template>
  <b-form-group
    label="Sipariş Tipi"
    label-for="id_com_sparepart_order_type"
  >
    <validation-provider
      #default="{ errors }"
      name="Sipariş Tipi"
      rules="required"
    >
      <v-select
        id="id_com_sparepart_order_type"
        v-model="interview.orderSpareParts.id_com_sparepart_order_type"
        :options="orderTypes"
        :reduce="orderType => orderType.id"
        label="title"
        placeholder="Seçiniz"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'OrderTypesVue',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    interview() {
      return this.$store.getters['interviews/getInterview']
    },
    orderTypes() {
      return this.$store.getters['sparepartOrderTypes/getSparepart_order_types']
    },
  },
  created() {
    localize(this.locale)
    this.getOrderTypes()
  },
  methods: {
    getOrderTypes() {
      this.$store.dispatch('sparepartOrderTypes/sparepart_order_typesList', {
        select: [
          'com_sparepart_order_type.id AS id',
          'com_sparepart_order_type.title AS title',
        ],
      })
    },
  },
}
</script>

<style scoped></style>
