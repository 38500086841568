<template>
  <b-card>
    <b-form-group
      label="Görüşme Tipi"
      label-for="id_com_interview_type"
    >
      <validation-provider
        #default="{ errors }"
        name="Görüşme Tipi"
        rules="required"
      >
        <v-select
          id="id_com_interview_type"
          v-model="id_com_interview_type"
          label="title"
          placeholder="Seçiniz"
          :reduce="interviewType => interviewType.id"
          :options="interviewTypes"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </b-card>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import { BCard, BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'InterviewTypes',
  components: {
    BCard,
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      id_com_interview_type: null,
      locale: 'tr',
    }
  },
  computed: {
    interview() {
      return this.$store.getters['interviews/getInterview']
    },
    interviewTypes() {
      return this.$store.getters['interviewTypes/getInterview_types']
    },
  },
  watch: {
    id_com_interview_type(val) {
      this.interview.id_com_interview_type = val
      if (val === '1') {
        this.interview.interview_type_component = 'Sales'
      } else if (val === '2') {
        this.interview.interview_type_component = 'ServiceMechanical'
      } else if (val === '3') {
        this.interview.interview_type_component = 'SecondHand'
      } else if (val === '4') {
        this.interview.interview_type_component = 'Insurance'
      } else if (val === '5') {
        this.interview.interview_type_component = 'Rental'
      } else if (val === '6') {
        this.interview.interview_type_component = 'RealtySales'
      } else if (val === '7') {
        this.interview.interview_type_component = 'ServiceDamage'
      } else if (val === '8') {
        this.interview.interview_type_component = 'CustomerRelationship'
      } else if (val === '9') {
        this.interview.interview_type_component = 'SpareParts'
      } else if (val === '11') {
        this.interview.interview_type_component = 'AccessorySales'
      }
    },
  },
  created() {
    localize(this.locale)
    this.getInterviewTypes()
  },
  methods: {
    getInterviewTypes() {
      this.$store.dispatch('interviewTypes/interview_typesList', {
        select: [
          'com_interview_type.id AS id',
          'com_interview_type.title AS title',
        ],
      })
    },
  },
}
</script>

<style scoped>

</style>
