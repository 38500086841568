<template>
  <b-form-group
    label="İlgili Kişi"
    label-for="id_com_user1"
  >
    <validation-provider
      #default="{ errors }"
      name="İlgili Kişi"
      rules="required"
    >
      <v-select
        id="id_com_user1"
        v-model="interview.id_com_user1"
        :options="users"
        :reduce="user => user.id"
        label="title"
        placeholder="Seçiniz"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Meets',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    interview() {
      return this.$store.getters['interviews/getInterview']
    },
    users() {
      return this.$store.getters['users/getUsers']
    },
  },
  created() {
    localize(this.locale)
  },
}
</script>

<style scoped>

</style>
