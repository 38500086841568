<template>
  <b-form-group
    label="Gayrimenkul Tipi"
    label-for="id_com_realty_type"
  >
    <validation-provider
      #default="{ errors }"
      name="Gayrimenkul Tipi"
      rules="required"
    >
      <v-select
        id="id_com_realty_type"
        v-model="interview.id_com_realty_type"
        :options="types"
        :reduce="type => type.id"
        label="title"
        placeholder="Seçiniz"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'RealtyProjects',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  computed: {
    interview() {
      return this.$store.getters['interviews/getInterview']
    },
    types() {
      return this.$store.getters['realtyTypes/getRealty_sale_types']
    },
  },
  created() {
    localize('tr')
  },
}
</script>

<style scoped>

</style>
