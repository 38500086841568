<template>
  <div>
    <interview-types />
    <component
      :is="interview.interview_type_component"
      v-if="interview.interview_type_component"
    />
  </div>
</template>
<script>
import InterviewTypes from '@/views/Interviews/forms/InterviewTypes.vue'
import Sales from '@/views/Interviews/components/Create/Sales.vue'
import ServiceMechanical from '@/views/Interviews/components/Create/ServiceMechanical.vue'
import ServiceDamage from '@/views/Interviews/components/Create/ServiceDamage.vue'
import SecondHand from '@/views/Interviews/components/Create/SecondHand.vue'
import Insurance from '@/views/Interviews/components/Create/Insurance.vue'
import Rental from '@/views/Interviews/components/Create/Rental.vue'
import SpareParts from '@/views/Interviews/components/Create/SpareParts.vue'
import CustomerRelationship from '@/views/Interviews/components/Create/CustomerRelationship.vue'
import RealtySales from '@/views/Interviews/components/Create/RealtySales.vue'
import AccessorySales from '@/views/Interviews/components/Create/AccessorySales.vue'

export default {
  name: 'Create',
  components: {
    InterviewTypes,
    Sales,
    ServiceMechanical,
    ServiceDamage,
    SecondHand,
    Insurance,
    Rental,
    SpareParts,
    CustomerRelationship,
    RealtySales,
    AccessorySales,
  },
  data() {
    return {
      locale: 'tr',
      submitStatus: false,
    }
  },
  computed: {
    interview() {
      return this.$store.getters['interviews/getInterview']
    },
  },
}
</script>

<style scoped>

</style>
