<template>
  <b-button
    variant="danger"
    :disabled="interview.submitStatus"
    @click="saveAction"
  >
    Kaydet
  </b-button>
</template>

<script>
import { BButton } from 'bootstrap-vue'

export default {
  name: 'SaveButton',
  components: {
    BButton,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: {
    actionMethods: {
      type: Function,
      required: true,
    },
  },
  computed: {
    interview() {
      return this.$store.getters['interviews/getInterview']
    },
  },
  methods: {
    saveAction() {
      this.actionMethods()
    },
  },
}
</script>

<style scoped>

</style>
