<template>
  <div v-if="interviewPoll.brand">
    <b-card :title="interviewPoll.brand + ' ' + interviewPoll.subject">
      <b-list-group>
        <b-list-group-item
          v-for="(item,key) in interviewPoll.poll"
          :key="key"
        >
          <div class="font-weight-bold text-primary mb-1">
            {{ item.questions }}
          </div>
          <v-select
            v-model="item.selected"
            :options="item.options"
            placeholder="Seçiniz"
          />
        </b-list-group-item>
      </b-list-group>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'InterviewPolls',
  components: {
    BCard,
    BListGroup,
    BListGroupItem,
    vSelect,
  },
  props: {
    idComBrand: {
      type: String,
      required: true,
    },
    idComInterviewSubject: {
      type: String,
      required: true,
    },
  },
  computed: {
    interviewPoll() {
      return this.$store.getters['interviewPolls/getInterviewPoll']
    },
    interview() {
      return this.$store.getters['interviews/getInterview']
    },
  },
  watch: {
    idComBrand(val) {
      if (val) {
        if (this.idComInterviewSubject) {
          this.getInterviewPoll(val, this.idComInterviewSubject)
        }
      } else {
        this.interviewPoll = {
          brand: null,
          subject: null,
          pollcode: null,
          poll: [],
        }
      }
    },
    idComInterviewSubject(val) {
      if (val) {
        if (this.idComBrand) {
          this.getInterviewPoll(this.idComBrand, val)
        }
      } else {
        this.interviewPoll = {
          brand: null,
          subject: null,
          pollcode: null,
          poll: [],
        }
      }
    },
    interviewPoll: {
      deep: true,
      handler(val) {
        if (val.pollcode) {
          this.interview.pollcode = val.pollcode
          this.interview.interview_poll = this.interviewPoll
        } else {
          this.interview.pollcode = null
          this.interview.interview_poll = {}
        }
      },
    },
  },
  methods: {
    getInterviewPoll(brand, subject) {
      this.$store.dispatch('interviewPolls/interviewPoll', {
        id_com_brand: brand,
        id_com_interview_subject: subject,
      })
    },
  },
}
</script>
