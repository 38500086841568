<template>
  <div>
    <b-row
      v-for="(part,index) in interview.orderSpareParts.parts"
      :key="index"
    >
      <b-col>
        <b-form-group
          label="Yedek Parça Kodu"
          label-for="id_com_brand"
        >
          <validation-provider
            #default="{ errors }"
            name="Yedek Parça Kodu"
            rules="required"
          >
            <b-form-input
              v-model="part.spare_code"
              placeholder="Yedek Parça Kodu"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group
          label="Adet"
          label-for="id_com_brand"
        >
          <validation-provider
            #default="{ errors }"
            name="Adet"
            rules="required"
          >
            <b-form-input
              v-model="part.piece"
              type="number"
              placeholder="Adet"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        v-if="index > 0"
        cols="auto"
        class="mt-2"
        @click="removeItem(index)"
      >
        <b-button variant="outline-danger">
          Sil
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-button
          variant="primary"
          size="sm"
          @click="addPartRow"
        >
          <FeatherIcon icon="PlusIcon" />
          Parça Ekle
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import {
  BFormGroup, BFormInput, BRow, BCol, BButton,
} from 'bootstrap-vue'

export default {
  name: 'SparePartLines',
  components: {
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
    ValidationProvider,
  },
  computed: {
    interview() {
      return this.$store.getters['interviews/getInterview']
    },
  },
  created() {
    localize('tr')
  },
  methods: {
    addPartRow() {
      this.interview.orderSpareParts.parts.push({
        spare_code: null,
        piece: null,
      })
    },
    removeItem(index) {
      this.interview.orderSpareParts.parts.splice(index, 1)
    },
  },
}
</script>

<style scoped></style>
