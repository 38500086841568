<template>
  <b-form-group
    label="Marka"
    label-for="id_com_swap_brand"
  >
    <validation-provider
      #default="{ errors }"
      name="Marka"
      rules="required"
    >
      <v-select
        id="id_com_swap_brand"
        v-model="interview.id_com_swap_brand"
        :options="brands"
        :reduce="brand => brand.id"
        label="title"
        placeholder="Seçiniz"
        @input="getModels"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'SwapBrands',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    interview() {
      return this.$store.getters['interviews/getInterview']
    },
    brands() {
      return this.$store.getters['swapbrands/getSwapbrands']
    },
  },
  created() {
    localize(this.locale)
    this.getBrands()
  },
  methods: {
    getBrands() {
      this.$store.dispatch('swapbrands/swapbrandsList', {
        select: [
          'com_swap_brand.id AS id',
          'com_swap_brand.name AS title',
        ],
      })
    },
    getModels() {
      this.interview.id_com_swap_model = null
      if (this.interview.id_com_swap_brand) {
        this.$store.dispatch('swapmodels/swapmodelsList', {
          select: [
            'com_swap_model.id AS id',
            'com_swap_model.title AS title',
          ],
          where: {
            'com_swap_model.id_com_swap_brand': this.interview.id_com_swap_brand,
          },
        })
      }
    },
  },
}
</script>

<style scoped>

</style>
