<template>
  <b-modal
    v-model="modalShow"
    title="Müşteri KVKK Onayı"
    ok-only
    centered
    hide-footer
    no-close-on-esc
    hide-header-close
    no-close-on-backdrop
  >
    <kvkk-form
      :customer-id="customerId"
      :refresh-data="refreshData"
    />
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue'
import KvkkForm from '@/layouts/components/common/KvkkForm.vue'

export default {
  name: 'KvkkModal',
  components: {
    BModal,
    KvkkForm,
  },
  props: {
    refreshData: {
      type: Function,
      required: true,
    },
    modalShow: {
      type: Boolean,
      default: false,
    },
    customerId: {
      // eslint-disable-next-line vue/require-prop-type-constructor,no-bitwise
      type: String | Number,
      required: true,
    },
  },
}
</script>
