<template>
  <b-form-group
    label="Km"
    label-for="km"
  >
    <cleave
      id="km"
      v-model="swapcar.km"
      class="form-control"
      :raw="true"
      :options="options.km"
      placeholder="Km"
    />
  </b-form-group>
</template>

<script>
import { localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

export default {
  name: 'Km',
  components: {
    BFormGroup,
    Cleave,
  },
  data() {
    return {
      options: {
        km: {
          numeral: true,
        },
      },
    }
  },
  computed: {
    swapcar() {
      return this.$store.getters['swapcars/getSwapcar']
    },
  },
  created() {
    localize('tr')
  },
}
</script>

<style scoped></style>
