<template>
  <b-form-group
    label="İlgili Araç"
    label-for="inallar2_advert"
  >
    <validation-provider
      #default="{ errors }"
      name="İlgili Araç"
      rules="required"
    >
      <v-select
        id="inallar2_advert"
        v-model="interview.inallar2_advert"
        :options="g2Stocks"
        :reduce="g2Stock => g2Stock.id"
        label="title"
        placeholder="Seçiniz"
      >
        <template v-slot:option="option">
          <div>
            {{ option.brand }} {{ option.model }}
          </div>
          <div>
            <small class="text-danger">{{ option.myear }}</small>
          </div>
          <div>
            <small class="text-warning">{{ option.license_plate }}</small>
          </div>
          <div>
            <small class="text-muted">İlan No: {{ option.id_com_advert }}</small>
          </div>
        </template>
        <template v-slot:selected-option="option">
          <div>
            {{ option.brand }} {{ option.model }} <span class="text-primary">{{ option.license_plate }}</span>
          </div>
        </template>
      </v-select>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'G2Stocks',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  computed: {
    interview() {
      return this.$store.getters['interviews/getInterview']
    },
    g2Stocks() {
      return this.$store.getters['g2stock/getG2Stocks']
    },
  },
  created() {
    localize('tr')
    this.getG2Stocks()
  },
  methods: {
    getG2Stocks() {
      this.$store.dispatch('g2stock/g2Stocks', {
        select: [
          'com_g2stock.id AS id',
          'com_g2stock.id_com_advert AS id_com_advert',
          'com_g2stock.hardware_pack AS hardware',
          'com_g2stock.myear AS myear',
          'com_g2stock.license_plate AS license_plate',
          'com_swap_brand.name AS brand',
          'com_swap_model.title AS model',
          'com_fuel.title AS fuel',
          'com_gear.title AS gear',
        ],
        where: {
          'com_g2stock.id_com_g2stock_status': 0,
        },
      })
    },
  },
}
</script>

<style scoped>

</style>
