<template>
  <div>
    <b-form-group
      label="Hatırlatma Ekle"
      label-for="add_alert"
    >
      <b-form-checkbox
        id="add_alert"
        v-model="add_alert"
        variant="info"
        switch
      />
    </b-form-group>
    <div v-if="add_alert">
      <b-form-group
        label="Tarih"
        label-for="alert_date"
      >
        <validation-provider
          #default="{ errors }"
          name="Hatırlatma Tarihi"
          rules="required"
        >
          <b-form-datepicker
            id="alert_date"
            v-model="interview.alert_date"
            v-bind="{labelNoDateSelected: 'Hatırlatma Tarihi',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
            locale="tr"
            start-weekday="1"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group
        label="Hatırlatma Notu"
        label-for="alert_notes"
      >
        <validation-provider
          #default="{ errors }"
          name="Hatırlatma Notu"
          rules="required"
        >
          <b-form-textarea
            id="alert_notes"
            v-model="interview.alert_notes"
            placeholder="Hatırlatma Notu"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import {
  BFormGroup, BFormCheckbox, BFormDatepicker, BFormTextarea,
} from 'bootstrap-vue'

export default {
  name: 'NewAlert',
  components: {
    BFormGroup,
    BFormCheckbox,
    BFormDatepicker,
    BFormTextarea,
    ValidationProvider,
  },
  data() {
    return {
      add_alert: false,
      alert_date: null,
      alert_notes: null,
      locale: 'tr',
    }
  },
  computed: {
    interview() {
      return this.$store.getters['interviews/getInterview']
    },
    brands() {
      return this.$store.getters['brands/getBrands']
    },
  },
  created() {
    localize(this.locale)
  },
}
</script>

<style scoped>

</style>
