<template>
  <div>
    <b-form-group
      label="Araç Kartı"
      label-for="id_com_cars"
    >
      <validation-provider
        #default="{ errors }"
        name="Araç Kartı"
        rules="required"
      >
        <v-select
          id="id_com_cars"
          v-model="interview.id_com_cars"
          :options="cars"
          :reduce="car => car.id"
          label="title"
          placeholder="Seçiniz"
        >
          <template v-slot:option="option">
            <div v-if="option.brand && option.model">
              {{ option.brand }} {{ option.model }}
            </div>
            <div v-else-if="option.swap_brand && option.swap_model">
              {{ option.swap_brand }} {{ option.swap_model }}
            </div>
            <div>
              <small class="text-danger">{{ option.myear }}</small>
            </div>
            <div>
              <small class="text-warning">{{ option.license_plate }}</small>
            </div>
          </template>
          <template v-slot:selected-option="option">
            <div v-if="option.brand && option.model">
              {{ option.brand }} {{ option.model }} <span class="text-primary">{{ option.license_plate }}</span>
            </div>
            <div v-else-if="option.swap_brand && option.swap_model">
              {{ option.swap_brand }} {{ option.swap_model }} <span class="text-primary">{{ option.license_plate }}</span>
            </div>
          </template>
        </v-select>
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <b-form-group>
      <b-button
        v-b-toggle.sidebar-add-new-car
        :disabled="interview.id_com_cars"
        type="submit"
        variant="danger"
        size="sm"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-50"
        />
        Yeni Araç Kartı Oluştur
      </b-button>
    </b-form-group>
  </div>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import { BFormGroup, BButton, VBToggle } from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'Expertise',
  components: {
    BFormGroup,
    BButton,
    vSelect,
    ValidationProvider,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    interview() {
      return this.$store.getters['interviews/getInterview']
    },
    cars() {
      return this.$store.getters['cars/getCars']
    },
  },
  created() {
    localize(this.locale)
    this.getCars()
  },
  methods: {
    getCars() {
      this.$store.dispatch('cars/carsList', {
        select: [
          'com_cars.id as id',
          'com_swap_brand.name as swap_brand',
          'com_swap_model.title as swap_model',
          'com_brand.name as brand',
          'com_model.title as model',
          'com_cars.license_plate as license_plate',
          'com_cars.myear as myear',
        ],
        where: {
          'com_cars.id_com_customer': this.$route.params.id,
        },
      })
    },
  },
}
</script>

<style scoped>

</style>
