<template>
  <b-form-group
    label="Model"
    label-for="id_com_model"
  >
    <validation-provider
      #default="{ errors }"
      name="Model"
      rules="required"
    >
      <v-select
        id="id_com_model"
        v-model="interview.id_com_model"
        :options="models"
        :reduce="model => model.id"
        label="title"
        placeholder="Seçiniz"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Models',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  props: {
    id_com_brand: {
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    interview() {
      return this.$store.getters['interviews/getInterview']
    },
    models() {
      return this.$store.getters['models/getModels']
    },
  },
  watch: {
    id_com_brand(val) {
      this.interview.id_com_model = null
      if (val !== null) {
        this.getModels()
      }
    },
  },
  created() {
    localize(this.locale)
  },
  mounted() {
    this.getModels()
  },
  methods: {
    getModels() {
      this.$store.dispatch('models/modelsList', {
        select: [
          'com_model.id AS id',
          'com_model.title AS title',
        ],
        where: {
          'com_model.id_com_brand': this.id_com_brand,
        },
      })
    },
  },
}
</script>

<style scoped>

</style>
