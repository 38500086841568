<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-row>
        <b-col cols="4">
          <b-card title="Sıfır Satış Görüşmesi">
            <meets />
            <brands />
            <models
              v-if="interview.id_com_brand"
              :id_com_brand="interview.id_com_brand"
            />
            <test-drive />
            <expertise />
          </b-card>
        </b-col>
        <b-col>
          <b-card title="Görüşme Detay">
            <status-code />
            <interview-statuses
              v-if="interview.id_com_interview_type"
              :id_com_interview_type="interview.id_com_interview_type"
              first-status="1"
            />
            <interview-content />
            <new-alert />
            <save-button
              :disabled="interview.testdrive > 0 && !interview.id_com_testdrive_form_sms"
              :action-methods="submitForm"
            />          </b-card>
        </b-col>
      </b-row>
    </validation-observer>
    <add-expertise />
  </div>
</template>

<script>
import { ValidationObserver, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'
import Meets from '@/views/Interviews/forms/Meets.vue'
import Brands from '@/views/Interviews/forms/Brands.vue'
import Models from '@/views/Interviews/forms/Models.vue'
import TestDrive from '@/views/Interviews/forms/TestDrive.vue'
import Expertise from '@/views/Interviews/forms/Expertise.vue'
import StatusCode from '@/views/Interviews/forms/StatusCode.vue'
import InterviewStatuses from '@/views/Interviews/forms/Interview_statuses.vue'
import InterviewContent from '@/views/Interviews/forms/Content.vue'
import NewAlert from '@/views/Interviews/forms/NewAlert.vue'
import SaveButton from '@/views/Interviews/forms/SaveButton.vue'
import AddExpertise from '@/views/Interviews/components/AddExpertise.vue'

export default {
  name: 'Sales',
  components: {
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    Meets,
    Brands,
    Models,
    TestDrive,
    Expertise,
    StatusCode,
    InterviewStatuses,
    InterviewContent,
    NewAlert,
    AddExpertise,
    SaveButton,
  },
  computed: {
    interview() {
      return this.$store.getters['interviews/getInterview']
    },
  },
  created() {
    localize('tr')
    this.interview.initial = '1'
    this.interview.id_com_interview_status = '6'
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.interview.submitStatus = true
          this.$store.dispatch('interviews/interviewSave', this.interview)
        }
      })
    },
  },
}
</script>

<style scoped></style>
