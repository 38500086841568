<template>
  <b-sidebar
    id="sidebar-add-TestForm"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Test Sürüşü Formu Oluştur
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <validation-observer ref="TestDriveRules">
        <b-form
          class="p-2"
        >
          <b-form-group
            label="Test Sürüşü Yapılacak Araç"
            label-for="vehicle"
          >
            <validation-provider
              #default="{ errors }"
              name="Test Sürüşü Yapılacak Araç"
              rules="required"
            >
              <v-select
                v-model="interview.testDriveForm.id_com_model"
                label="title"
                :reduce="item => item.id"
                :options="models"
                placeholder="Seçiniz"
                :disabled="!models.length"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Mevcut Aracının Markası / Modeli / Yılı"
            label-for="current_vehicle"
          >
            <validation-provider
              #default="{ errors }"
              name="Mevcut Aracının Markası / Modeli / Yılı"
              rules="required"
            >
              <b-form-input
                id="current_vehicle"
                v-model="interview.testDriveForm.current_vehicle"
                placeholder="Mevcut Aracının Markası / Modeli / Yılı"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Sms Doğrulaması"
            label-for="sms"
          >
            <test-drive-inline
              id="sms"
              :customer-id="interview.id_com_customer"
              :refresh-data="getCustomer"
              :save-form="submitForm"
            />
          </b-form-group>
          <div>
            <div class="mt-2">
              <small>Test sürüşü formu görüşmeyi kaydettikten sonra indirilebilir olacaktır.<span class="text-danger">*</span></small>
            </div>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>
<script>
import { ValidationObserver, localize, ValidationProvider } from 'vee-validate'
import {
  BSidebar, BForm, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import TestDriveInline from '@/views/Interviews/components/testDrive/TestDriveInline.vue'

export default {
  components: {
    TestDriveInline,
    BFormInput,
    ValidationProvider,
    BFormGroup,
    BSidebar,
    BForm,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      locale: 'tr',
      submitStatus: false,
    }
  },
  computed: {
    interview() {
      return this.$store.getters['interviews/getInterview']
    },
    models() {
      return this.$store.getters['models/getModels']
    },
    saveStatus() {
      return this.$store.getters['interviews/getTestFormSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: 'Test Sürüşü Formu Kaydedildi',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: 'Test Sürüşü Formu Kaydedilemedi',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    localize(this.locale)
  },
  methods: {
    getCustomer() {
      this.$store.dispatch('customers/customerView', 233436)
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.$refs.TestDriveRules.validate().then(success => {
        if (success) {
          this.submitStatus = true
          this.$store.dispatch('interviews/testFormSave', this.interview.testDriveForm)
            .then(res => {
              if (res.status) {
                this.interview.id_com_testdrive_form_sms = this.interview.testDriveForm.id_com_testdrive_form_sms
                this.$store.commit('interviews/RESET_TESTFORM_SMS_RESULT')
                this.$store.commit('interviews/RESET_TEST_SMS_FORM')
              }
              this.submitStatus = false
            })
        }
      })
    },
  },
}
</script>
