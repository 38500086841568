<template>
  <test-drive-form
    :customer-id="customerId"
    :refresh-data="refreshData"
    :save-form="saveForm"
  />
</template>

<script>
import TestDriveForm from '@/views/Interviews/components/testDrive/TestDriveForm.vue'

export default {
  name: 'TestDriveInline',
  components: {
    TestDriveForm,
  },
  props: {
    refreshData: {
      type: Function,
      required: true,
    },
    customerId: {
      // eslint-disable-next-line vue/require-prop-type-constructor,no-bitwise
      type: String | Number,
      required: true,
    },
    saveForm: {
      type: Function,
      required: true,
    },
  },
}
</script>
