<template>
  <div>
    <template v-if="!interview.id_com_swapcar">
      <b-form-group
        label="Ekspertiz Talebi"
        label-for="expertise_type"
      >
        <validation-provider
          #default="{ errors }"
          name="Ekspertiz Talebi"
          rules="required"
        >
          <v-select
            id="expertise_type"
            v-model="interview.expertise_type"
            :options="expertiseTypes"
            :reduce="expertiseType => expertiseType.id"
            label="title"
            placeholder="Seçiniz"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-button
        v-if="interview.expertise_type > 0"
        v-b-toggle.sidebar-add-expertise
        type="submit"
        variant="danger"
        @click="$store.commit('swapcars/SWAPCAR_RESET')"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-50"
        />
        {{ interview.expertise_type === '1'? 'Ön Fiyat Formu' : '' }}
        {{ interview.expertise_type === '2'? 'Ekspertiz Formu' : '' }}
      </b-button>
    </template>
    <template v-else>
      <b-button
        v-if="interview.id_com_swapcar"
        variant="success"
        block
        :href="baseUrl + '/exports/data/expertise/pdf?id=' + interview.id_com_swapcar + '&token=' + downloadToken"
        target="_blank"
      >
        <FeatherIcon icon="DownloadIcon" />
        Takas Araç PDF İndir
      </b-button>
    </template>
  </div>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import { BFormGroup, BButton, VBToggle } from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'Expertise',
  components: {
    BFormGroup,
    BButton,
    vSelect,
    ValidationProvider,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      downloadToken: localStorage.getItem('downloadToken'),
      baseUrl: this.$store.state.app.baseURL,
      locale: 'tr',
    }
  },
  computed: {
    interview() {
      return this.$store.getters['interviews/getInterview']
    },
    expertiseTypes() {
      return [
        {
          id: '1',
          title: 'Ön Fiyat',
        },
        {
          id: '2',
          title: 'Ekspertiz',
        },
        {
          id: '0',
          title: 'Takas Yok',
        },
      ]
    },
  },
  created() {
    localize(this.locale)
  },
}
</script>

<style scoped>

</style>
