<template>
  <b-form-group
    label="Model"
    label-for="id_com_swap_model"
  >
    <validation-provider
      #default="{ errors }"
      name="Model"
      rules="required"
    >
      <v-select
        id="id_com_swap_model"
        v-model="interview.id_com_swap_model"
        :options="models"
        :reduce="model => model.id"
        label="title"
        placeholder="Seçiniz"
        :disabled="!interview.id_com_swap_brand"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'SwapModels',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    interview() {
      return this.$store.getters['interviews/getInterview']
    },
    models() {
      return this.$store.getters['swapmodels/getSwapmodels']
    },
  },
  created() {
    localize(this.locale)
  },
}
</script>

<style scoped>

</style>
