<template>
  <b-card title="Yedek Parça Siparişi">
    <b-row>
      <b-col>
        <brands />
      </b-col>
      <b-col>
        <order-types />
      </b-col>
    </b-row>
    <spare-part-lines />
  </b-card>
</template>
<script>
import { BRow, BCol, BCard } from 'bootstrap-vue'
import Brands from '@/views/Interviews/components/OrderSpareParts/forms/Brands.vue'
import OrderTypes from '@/views/Interviews/components/OrderSpareParts/forms/OrderTypes.vue'
import SparePartLines from '@/views/Interviews/components/OrderSpareParts/forms/SparePartLines.vue'

export default {
  name: 'OrderSpareParts',
  components: {
    BRow,
    BCol,
    BCard,
    Brands,
    OrderTypes,
    SparePartLines,
  },
}
</script>
