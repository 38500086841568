<template>
  <b-form-group
    label="Fatura Durumu"
    label-for="brand"
  >
    <v-select
      id="brand"
      v-model="swapcar.invoice_type"
      label="title"
      :reduce="invoiceType => invoiceType.id"
      :options="invoiceTypes"
      placeholder="Fatura Durumu"
    />
  </b-form-group>
</template>
<script>
import { localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

export default {
  name: 'Brands',
  components: {
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    swapcar() {
      return this.$store.getters['swapcars/getSwapcar']
    },
    invoiceTypes() {
      return [
        {
          id: '0',
          title: 'Faturasız',
        },
        {
          id: '1',
          title: 'Faturalı',
        },
      ]
    },
  },
  created() {
    localize(this.locale)
  },
}
</script>

<style scoped>
</style>
