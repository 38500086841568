<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-row>
        <b-col cols="4">
          <b-card title="Müşteri İlişkileri Görüşmesi">
            <meets />
            <customer-cars />
            <chasis />
            <brands />
            <interview-subjects />
            <users />
          </b-card>
        </b-col>
        <b-col>
          <div v-if="['1','2','3','4','5','6','8','13'].includes(interview.id_com_brand) || ['16','17','18'].includes(interview.id_com_interview_subject)">
            <interview-polls
              :id-com-brand="interview.id_com_brand"
              :id-com-interview-subject="interview.id_com_interview_subject"
            />
          </div>
          <b-card title="Görüşme Detay">
            <div v-if="!['1','2','3','4','5','6','8','13'].includes(interview.id_com_brand) || !['16','17','18'].includes(interview.id_com_interview_subject)">
              <interview-statuses
                v-if="interview.id_com_interview_type"
                :id_com_interview_type="interview.id_com_interview_type"
                first-status="1"
              />
            </div>
            <div v-else>
              <interview-statuses
                v-if="interview.id_com_interview_type"
                id_com_interview_type="10"
                first-status="0"
              />
            </div>
            <interview-content />
            <new-alert />
            <save-button :action-methods="submitForm" />
          </b-card>
        </b-col>
      </b-row>
    </validation-observer>
    <add-car />
  </div>
</template>

<script>
import { ValidationObserver, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import {
  BCard, BRow, BCol,
} from 'bootstrap-vue'
import Meets from '@/views/Interviews/forms/Meets.vue'
import Brands from '@/views/Interviews/forms/BrandsAll.vue'
import Users from '@/views/Interviews/forms/Users.vue'
import Chasis from '@/views/Interviews/forms/Chasis.vue'
import InterviewStatuses from '@/views/Interviews/forms/Interview_statuses.vue'
import InterviewContent from '@/views/Interviews/forms/Content.vue'
import NewAlert from '@/views/Interviews/forms/NewAlert.vue'
import SaveButton from '@/views/Interviews/forms/SaveButton.vue'
import CustomerCars from '@/views/Interviews/forms/CustomerCars.vue'
import AddCar from '@/views/Interviews/components/AddCar.vue'
import InterviewSubjects from '@/views/Interviews/forms/InterviewSubjects.vue'
import InterviewPolls from '@/views/Interviews/components/InterviewPolls/Index.vue'

export default {
  name: 'CustomerRelationship',
  components: {
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    Meets,
    Brands,
    Users,
    Chasis,
    InterviewStatuses,
    InterviewContent,
    NewAlert,
    CustomerCars,
    AddCar,
    InterviewSubjects,
    InterviewPolls,
    SaveButton,
  },
  computed: {
    interview() {
      return this.$store.getters['interviews/getInterview']
    },
  },
  watch: {
    interview: {
      deep: true,
      handler(val) {
        if (!['16', '17', '18'].includes(val.id_com_interview_subject)) {
          this.interview.id_com_interview_status = '57'
        }
      },
    },
  },
  created() {
    this.getBrands()
    this.getUsers()
    localize('tr')
    this.interview.initial = '1'
    this.interview.id_com_interview_status = '57'
  },
  methods: {
    getBrands() {
      this.$store.dispatch('brands/brandsList', {
        select: [
          'com_brand.id AS id',
          'com_brand.name AS title',
        ],
        where: {},
      })
    },
    getUsers() {
      this.$store.dispatch('users/usersList', {
        select: [
          'com_user.id AS id',
          'com_user.name AS title',
        ],
        where: {
          'com_user.status': 1,
        },
      })
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.interview.submitStatus = true
          this.$store.dispatch('interviews/interviewSave', this.interview)
        }
      })
    },
  },
}
</script>

<style scoped></style>
